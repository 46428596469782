import { FC, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { HomeLayout } from '../HomeLayout';
import { NotFound } from '../NotFound';
import { Tracks } from '../Tracks';
import { Channels } from '../Channels';
import { VybeCollections } from '../VybeCollections';
import { ROUTES } from '../../routeConstants';
import { useUserCustomClaims } from 'hooks';

const RedirectComponent: FC<{ route: string }> = ({ route }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(route, { replace: true });
  }, [navigate, route]);
  return null;
};

export const Home = () => {
  const userCustomClaims = useUserCustomClaims();

  return (
    <div style={{ background: '#fff' }}>
      <Routes>
        <Route element={<HomeLayout />}>
          <Route index element={<RedirectComponent route={ROUTES.TRACKS} />} />
          <Route path={ROUTES.TRACKS} element={<Tracks />} />
          <Route path={`${ROUTES.TRACK}/:id`} element={<div>track/id</div>} />
          {userCustomClaims.isSuperAdmin && <Route path={ROUTES.CHANNELS} element={<Channels />} />}
          {userCustomClaims.isSuperAdmin && <Route path={ROUTES.VYBE_COLLECTIONS} element={<VybeCollections />} />}
          <Route path={ROUTES.MIXES} element={<div>mixes</div>} />
          <Route path={ROUTES.BULK} element={<div>bulk</div>} />
          <Route path={'*'} element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
};
