import { Outlet } from 'react-router-dom';

export const HomeLayout = () => {
  return (
    <div style={{ background: '#fff', padding: 0 }}>
      <div style={{ marginTop: '20px' }}>
        <Outlet />
      </div>
    </div>
  );
};
