import { CamelotCode } from '../../utils/CamelotWheel';
import { BeatGrid } from '../../models';

export enum GENRES {
  HIP_HOP = 'HIP_HOP',
  RAP = 'RAP',
  TRAP = 'TRAP',
  R_N_B = 'R_N_B',
  DANCEHALL = 'DANCEHALL',
  REGGAE = 'REGGAE',
  DANCE = 'DANCE',
  POP = 'POP',
  AFROBEATS = 'AFROBEATS',
  AMAPIANO = 'AMAPIANO',
  REGGAETON = 'REGGAETON',
  SOCA = 'SOCA',
}

export const genreDisplayNames = {
  [GENRES.HIP_HOP]: 'Hip Hop',
  [GENRES.RAP]: 'Rap',
  [GENRES.TRAP]: 'Trap',
  [GENRES.R_N_B]: 'R&B',
  [GENRES.DANCEHALL]: 'Dancehall',
  [GENRES.REGGAE]: 'Reggae',
  [GENRES.DANCE]: 'Dance',
  [GENRES.POP]: 'Pop',
  [GENRES.AFROBEATS]: 'Afrobeats',
  [GENRES.AMAPIANO]: 'Amapiano',
  [GENRES.REGGAETON]: 'Reggaeton',
  [GENRES.SOCA]: 'Soca',
};

export enum TracksContentRating {
  CLEAN = 'CLEAN',
  MATURE = 'MATURE',
  EXPLICIT = 'EXPLICIT',
  UNRATED = 'UNRATED',
}

export enum CamelotKeys {
  '1A' = '1A',
  '2A' = '2A',
  '3A' = '3A',
  '4A' = '4A',
  '5A' = '5A',
  '6A' = '6A',
  '7A' = '7A',
  '8A' = '8A',
  '9A' = '9A',
  '10A' = '10A',
  '11A' = '11A',
  '12A' = '12A',
  '1B' = '1B',
  '2B' = '2B',
  '3B' = '3B',
  '4B' = '4B',
  '5B' = '5B',
  '6B' = '6B',
  '7B' = '7B',
  '8B' = '8B',
  '9B' = '9B',
  '10B' = '10B',
  '11B' = '11B',
  '12B' = '12B',
}

export type TrackEntry = {
  start_time: number;
  max_beats_to_entry: number;
};

export type Track = {
  id: number;
  created_at?: Date | string;
  updated_at?: Date | string;
  title: string;
  artist: string;
  album?: string;
  album_art_url?: string;
  release_date: Date | string;
  duration: number;
  bpm: number;
  beat_grid?: BeatGrid[];
  key?: CamelotCode;
  primary_genres: GENRES[];
  sub_genres: GENRES[];
  active?: boolean;
  md5_file_hash: string;
  stored_filename_original_file: string;
  size_in_mbs_original_file: number;
  download_url_original_file: string;
  content_rating?: TracksContentRating;
  sample_rate?: number;
  track_entries: TrackEntry[];
  download_url_original_mp3_converted_file: string;
  download_url_ebu_r128_normalized_file: string;
  download_url_peak_normalized_file: string;
  stored_filename_original_mp3_converted_file: string;
  stored_filename_ebu_r128_normalized_file: string;
  stored_filename_peak_normalized_file: string;
  spotify_id?: string;
};

export type TrackCreateObject = Omit<Track, 'id' | 'created_at' | 'updated_at'>;

export type BulkUploadTrack = {
  id: number;
  created_at?: Date | string;
  updated_at?: Date | string;
  title: string;
  active?: boolean;
  md5_file_hash: string;
  stored_filename_original_file: string;
  size_in_mbs_original_file: number;
  download_url_original_file: string;
  download_url_original_mp3_converted_file: string;
  download_url_ebu_r128_normalized_file: string;
  download_url_peak_normalized_file: string;
  stored_filename_original_mp3_converted_file: string;
  stored_filename_ebu_r128_normalized_file: string;
  stored_filename_peak_normalized_file: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata: { [key: string]: any };
  archived?: boolean;
};

export type BulkUploadTrackCreateObject = Omit<
  BulkUploadTrack & Track,
  'created_at' | 'updated_at' | 'metadata' | 'archived'
>;

export interface TracksResponse {
  data: Track[];
  meta: {
    total_count: number;
  };
}

export type SpotifyTrackResult = {
  spotify_id: string;
  title: string;
  album: string;
  album_art_url: string;
  artist: string;
  preview_url: string;
  release_date: string;
  genres: string[];
};

// export type BeatGrid = {
//   beat: number;
//   start_time: number;
//   spb: number;
//   is_track_entry: boolean;
// };

export type BeatGridSettings = {
  start_time: number;
  end_time: number;
  spb: number;
};
