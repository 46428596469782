import isArray from 'lodash/isArray';
import dayjs from 'dayjs';
import { Checkbox, Tooltip, Tag, Space, Image } from 'antd';
import { LiteralUnion } from 'antd/es/_util/type';
import { PresetColorType, PresetStatusColorType } from 'antd/es/_util/colors';
import { ColumnGroupType, ColumnType } from 'antd/es/table';
import { DownloadOutlined } from '@ant-design/icons';
import { actionCreators } from '../../../Actions';
import { BeatGrid } from '../../../models';
import { GENRES, Track, TracksContentRating, genreDisplayNames } from '../types';

const contentRatingTagColorMap: Record<TracksContentRating, LiteralUnion<PresetColorType | PresetStatusColorType>> = {
  [TracksContentRating.EXPLICIT]: 'red',
  [TracksContentRating.MATURE]: 'volcano',
  [TracksContentRating.CLEAN]: 'green',
  [TracksContentRating.UNRATED]: 'blue',
};

export const getColumns = function (actions: typeof actionCreators) {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      fixed: 'left',
      width: 70,
      render: (id: number) => (
        <div>
          <Tooltip placement="top" title={id}>
            <i>{id}</i>
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      fixed: 'left',
      sorter: true,
    },
    {
      title: 'Last Updated',
      dataIndex: 'updated_at',
      defaultSortOrder: 'descend',
      sorter: true,
      render: (updated_at: string) => {
        const formattedDate = dayjs(updated_at).format('MM/DD/YYYY');
        const formattedTime = dayjs(updated_at).format('HH:mm:ss');

        return (
          updated_at && (
            <Tooltip placement="top" title={`${formattedDate} ${formattedTime}`}>
              <i>{`${formattedDate} ${formattedTime}`}</i>
            </Tooltip>
          )
        );
      },
    },
    {
      title: 'Release Date',
      dataIndex: 'release_date',
      sorter: true,
      width: 120,
      render: (release_date: string) => {
        const formattedDate = dayjs(release_date).format('MMM YYYY');

        return release_date && formattedDate;
      },
    },
    {
      title: 'Artist',
      dataIndex: 'artist',
      sorter: true,
    },
    {
      title: 'Album',
      dataIndex: 'album',
      sorter: true,
    },
    {
      title: 'Album Art',
      dataIndex: 'album_art_url',
      sorter: true,
      render: (album_art_url) => (
        <div>
          <Image src={album_art_url} style={{ pointerEvents: 'none' }} preview />
        </div>
      ),
    },
    {
      title: 'Download',
      dataIndex: 'download_url_original_file',
      width: 100,
      align: 'center',
      render: (download_url_original_file: string) => (
        <a href={download_url_original_file} target="_blank" rel="noreferrer">
          <DownloadOutlined />
        </a>
      ),
    },
    {
      title: 'BPM',
      dataIndex: 'bpm',
      sorter: true,
      width: 80,
      render: (val: number) => <span>{Number(val).toFixed(2)}</span>,
    },
    {
      title: 'Key',
      dataIndex: 'key',
      sorter: true,
      width: 50,
    },
    {
      title: 'Content Rating',
      dataIndex: 'content_rating',
      sorter: true,
      width: 140,
      render: (content_rating: TracksContentRating) => (
        <Tag color={contentRatingTagColorMap[content_rating]}>{content_rating?.toUpperCase()}</Tag>
      ),
    },
    {
      title: 'Genre',
      dataIndex: 'primary_genres',
      sorter: true,
      render: (primary_genres: GENRES[], { sub_genres }) => {
        const hasSubGenres = isArray(sub_genres) && sub_genres.length > 0;
        const listOfSubGenres = hasSubGenres ? sub_genres.map((g) => genreDisplayNames[g]).join(', ') : '';
        return (
          <Tooltip placement="top" title={hasSubGenres && `Sub Genres: ${listOfSubGenres}`}>
            <Space wrap>
              {primary_genres?.map((genre) => (
                <Tag key={genre} color="default">
                  {genreDisplayNames[genre]}
                </Tag>
              ))}
            </Space>
          </Tooltip>
        );
      },
    },
    {
      title: 'Active',
      dataIndex: 'active',
      sorter: true,
      width: 100,
      align: 'center',
      render: (val: boolean) => <Checkbox checked={val} disabled />,
    },
    {
      title: 'Beat Grid',
      dataIndex: 'beat_grid',
      sorter: true,
      width: 100,
      align: 'center',
      render: (beat_grid: BeatGrid[]) => {
        const totalBeatGrid = isArray(beat_grid) ? beat_grid.length : 0;
        return <Checkbox checked={!!totalBeatGrid} disabled />;
      },
    },
    {
      title: 'Actions',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (track: Track) => (
        <a
          onClick={() => {
            actions.tracks.setCurrentlyEditedTrack(track);
            actions.tracks.setTrackModalVisibilityState(true);
          }}
        >
          Edit
        </a>
      ),
    },
  ] as (ColumnGroupType<Track> | ColumnType<Track>)[];
};
