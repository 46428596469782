type BeatGridArgs = {
  beat: number;
  start_time: number;
  spb: number;
  is_track_entry: boolean;
};

export class BeatGrid {
  public beat: number;
  public start_time: number;
  public spb: number;
  public is_track_entry: boolean;

  constructor(args: BeatGridArgs) {
    this.beat = args.beat;
    this.start_time = args.start_time;
    this.spb = args.spb;
    this.is_track_entry = args.is_track_entry;

    return {
      beat: args.beat,
      start_time: args.start_time,
      spb: args.spb,
      is_track_entry: args.is_track_entry,
    };
  }
}
