import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Epic, ofType } from 'redux-observable';
import { switchMap, catchError } from 'rxjs/operators';
import type { RootAction, RootState } from '../../services/ReduxService/store';
import { AwsChannel, AwsChannelCreateObject, GoogleChannelCreateObject, googleLiveStreamApiChannel } from './types';
import { ApiService } from '../../utils/ApiService';
import { of, concat, from } from 'rxjs';
import { AxiosError } from 'axios';

// Define a type for the slice state
export interface ChannelsState {
  awsChannels: AwsChannel[];
  googleChannels: googleLiveStreamApiChannel[];
  isLoading: boolean;
  isChannelModalOpen: boolean;
}

// Define the initial state using that type
export const initialState: ChannelsState = {
  awsChannels: [],
  googleChannels: [],
  isLoading: false,
  isChannelModalOpen: false,
};

export const channelSlice = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    fetchAwsChannels: (state) => state,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createAwsChannel: (state, action: PayloadAction<AwsChannelCreateObject>) => state,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteAwsChannel: (state, action: PayloadAction<string>) => state,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    startAwsChannel: (state, action: PayloadAction<string>) => state,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    stopAwsChannel: (state, action: PayloadAction<string>) => state,
    setAwsChannels: (state, action: PayloadAction<AwsChannel[]>) => {
      state.awsChannels = action.payload;
    },

    fetchGoogleChannels: (state) => state,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createGoogleChannel: (state, action: PayloadAction<GoogleChannelCreateObject>) => state,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteGoogleChannel: (state, action: PayloadAction<string>) => state,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    startGoogleChannel: (state, action: PayloadAction<string>) => state,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    stopGoogleChannel: (state, action: PayloadAction<string>) => state,
    setGoogleChannels: (state, action: PayloadAction<googleLiveStreamApiChannel[]>) => {
      state.googleChannels = action.payload;
    },
    setLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setChannelModalVisibilityState: (state, action: PayloadAction<boolean>) => {
      state.isChannelModalOpen = action.payload;
    },
  },
});

export const channelActions = channelSlice.actions;
export const {
  fetchAwsChannels,
  createAwsChannel,
  deleteAwsChannel,
  startAwsChannel,
  stopAwsChannel,
  setAwsChannels,
  setLoadingState,
  fetchGoogleChannels,
  createGoogleChannel,
  deleteGoogleChannel,
  startGoogleChannel,
  stopGoogleChannel,
  setGoogleChannels,
  setChannelModalVisibilityState,
} = channelSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectChannelState = (state: RootState) => state.channels;

export default channelSlice.reducer;

type AwsChannelsResponse = {
  channels: AwsChannel[];
};

type GoogleChannelsResponse = {
  channels: googleLiveStreamApiChannel[];
};

export const onFetchAwsChannelsEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    ofType(fetchAwsChannels.type),
    switchMap(() => {
      return concat(
        of(setLoadingState(true)),
        from(ApiService.getAllCollectionValues<AwsChannelsResponse>('channels/aws').then((data) => data.data)).pipe(
          switchMap((data) => {
            return [setAwsChannels(data.channels)];
          }),
        ),
        of(setLoadingState(false)),
      );
    }),
    catchError((err: Error) => {
      console.error('FATAL ERROR: err > channels > onFetchAwsChannelsEpic: ', err);
      message.error(err.message);
      return of(setLoadingState(false));
    }),
  );

export const onFetchGoogleChannelsEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    ofType(fetchGoogleChannels.type),
    switchMap(() => {
      return concat(
        of(setLoadingState(true)),
        from(
          ApiService.getAllCollectionValues<GoogleChannelsResponse>('channels/google').then((data) => data.data),
        ).pipe(
          switchMap((data) => {
            return [setGoogleChannels(data.channels)];
          }),
        ),
        of(setLoadingState(false)),
      );
    }),
    catchError((err: Error) => {
      console.error('FATAL ERROR: err > channels > onFetchGoogleChannelsEpic: ', err);
      message.error(err.message);
      return of(setLoadingState(false));
    }),
  );

export const onStartGoogleChannelsEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    ofType(startGoogleChannel.type),
    switchMap((action) => {
      const id = action.payload;
      return concat(
        of(setLoadingState(true)),
        from(ApiService.doGenericPost(`channels/google/start/${id}`).then((data) => data)).pipe(
          switchMap(() => {
            message.success(`Channel ${id} started successfully!`);
            return of(fetchGoogleChannels(), setLoadingState(false));
          }),
        ),
        of(setLoadingState(false)),
      );
    }),
    catchError((err: AxiosError<{ error: string }>) => {
      console.error('FATAL ERROR: err > channels > onStartGoogleChannelsEpic: ', err.response?.data.error);
      message.error(err.response?.data.error);
      return of(setLoadingState(false));
    }),
  );

export const onStopGoogleChannelsEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    ofType(stopGoogleChannel.type),
    switchMap((action) => {
      const id = action.payload;
      return concat(
        of(setLoadingState(true)),
        from(ApiService.doGenericPost(`channels/google/stop/${id}`).then((data) => data)).pipe(
          switchMap(() => {
            message.success(`Channel ${id} stopped successfully!`);
            return of(fetchGoogleChannels(), setLoadingState(false));
          }),
        ),
        of(setLoadingState(false)),
      );
    }),
    catchError((err: AxiosError<{ error: string }>) => {
      console.error('FATAL ERROR: err > channels > onStopGoogleChannelsEpic: ', err.response?.data.error);
      message.error(err.response?.data.error);
      return of(setLoadingState(false));
    }),
  );

export const onStartAwsChannelsEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    ofType(startAwsChannel.type),
    switchMap((action) => {
      const id = action.payload;
      return concat(
        of(setLoadingState(true)),
        from(ApiService.doGenericPost(`channels/aws/start/${id}`).then((data) => data)).pipe(
          switchMap(() => {
            message.success(`Channel ${id} started successfully!`);
            return of(fetchAwsChannels(), setLoadingState(false));
          }),
        ),
        of(setLoadingState(false)),
      );
    }),
    catchError((err: AxiosError<{ error: string }>) => {
      console.error('FATAL ERROR: err > channels > onStartAwsChannelsEpic: ', err.response?.data.error);
      message.error(err.response?.data.error);
      return of(setLoadingState(false));
    }),
  );

export const onStopAwsChannelsEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    ofType(stopAwsChannel.type),
    switchMap((action) => {
      const id = action.payload;
      return concat(
        of(setLoadingState(true)),
        from(ApiService.doGenericPost(`channels/aws/stop/${id}`).then((data) => data)).pipe(
          switchMap(() => {
            message.success(`Channel ${id} stopped successfully!`);
            return of(fetchAwsChannels(), setLoadingState(false));
          }),
        ),
        of(setLoadingState(false)),
      );
    }),
    catchError((err: AxiosError<{ error: string }>) => {
      console.error('FATAL ERROR: err > channels > onStopAwsChannelsEpic: ', err.response?.data.error);
      message.error(err.response?.data.error);
      return of(setLoadingState(false));
    }),
  );

export const onDeleteAwsChannelsEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    ofType(deleteAwsChannel.type),
    switchMap((action) => {
      const id = action.payload;
      return concat(
        of(setLoadingState(true)),
        from(ApiService.doGenericDelete(`channels/aws/${id}`).then((data) => data)).pipe(
          switchMap(() => {
            message.success(`Channel ${id} deleted successfully!`);
            return of(fetchAwsChannels(), setLoadingState(false));
          }),
        ),
        of(setLoadingState(false)),
      );
    }),
    catchError((err: AxiosError<{ error: string }>) => {
      console.error('FATAL ERROR: err > channels > onDeleteAwsChannelsEpic: ', err.response?.data.error);
      message.error(err.response?.data.error);
      return of(setLoadingState(false));
    }),
  );

export const onDeleteGoogleChannelsEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    ofType(deleteGoogleChannel.type),
    switchMap((action) => {
      const id = action.payload;
      return concat(
        of(setLoadingState(true)),
        from(ApiService.doGenericDelete(`channels/google/${id}`).then((data) => data)).pipe(
          switchMap(() => {
            message.success(`Channel ${id} deleted successfully!`);
            return of(fetchGoogleChannels(), setLoadingState(false));
          }),
        ),
        of(setLoadingState(false)),
      );
    }),
    catchError((err: AxiosError<{ error: string }>) => {
      console.error('FATAL ERROR: err > channels > onDeleteGoogleChannelsEpic: ', err.response?.data.error);
      message.error(err.response?.data.error);
      return of(setLoadingState(false));
    }),
  );

export const onCreateAwsChannelsEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    ofType(createAwsChannel.type),
    switchMap((action) => {
      const requestData = action.payload;
      return concat(
        of(setLoadingState(true)),
        from(ApiService.doGenericPost(`channels/aws/`, requestData).then((data) => data)).pipe(
          switchMap(() => {
            message.success(`Channel ${requestData.name} created successfully!`);
            return of(fetchAwsChannels(), setChannelModalVisibilityState(false), setLoadingState(false));
          }),
        ),
        of(setLoadingState(false)),
      );
    }),
    catchError((err: AxiosError<{ error: string }>) => {
      console.error('FATAL ERROR: err > channels > onCreateAwsChannelsEpic: ', err.response?.data.error);
      message.error(err.response?.data.error);
      return of(setLoadingState(false));
    }),
  );

export const onCreateGoogleChannelsEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    ofType(createGoogleChannel.type),
    switchMap((action) => {
      const requestData = action.payload;
      return concat(
        of(setLoadingState(true)),
        from(ApiService.doGenericPost(`channels/google/`, requestData).then((data) => data)).pipe(
          switchMap(() => {
            message.success(`Channel ${requestData.name} created successfully!`);
            return of(fetchGoogleChannels(), setChannelModalVisibilityState(false), setLoadingState(false));
          }),
        ),
        of(setLoadingState(false)),
      );
    }),
    catchError((err: AxiosError<{ error: string }>) => {
      console.error('FATAL ERROR: err > channels > onCreateGoogleChannelsEpic: ', err.response?.data.error);
      message.error(err.response?.data.error);
      return of(setLoadingState(false));
    }),
  );
