import { useContext, useEffect } from 'react';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import last from 'lodash/last';
import { AwsChannel, Platform, googleLiveStreamApiChannel } from './types';
import { selectChannelState } from './channelsSlice';
import { ActionsContext } from '../../Actions';
import { getColumns } from './helpers';
import { PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { ChannelModal } from './ChannelModal';

const mapAWSChannels = (awsChannels: AwsChannel[]) => {
  return awsChannels.map((channel) => ({
    key: channel.Id,
    id: channel.Id,
    name: channel.Name,
    platform: Platform.AWS,
    state: channel.State,
    streamUrl: channel.Destinations[0].Settings[0].Url.replace('mediastoressl://', 'https://')
      .concat('_320k_aac')
      .concat('.m3u8'),
    inputUrl: channel.inputDetails.Destinations[0].Url,
  }));
};

const mapGoogleChannels = (googleChannels: googleLiveStreamApiChannel[]) => {
  return googleChannels.map((channel) => ({
    key: channel.name,
    id: last(channel.name.split('/')) as string,
    name: channel.name,
    platform: Platform.GOOGLE,
    state: channel.streamingState,
    streamUrl: `https://storage.googleapis.com/${channel.output.uri.split('//')[1]}/${channel.manifests[0].fileName}`,
    inputUrl: channel.inputDetails.uri,
  }));
};

export const Channels = () => {
  const { actions } = useContext(ActionsContext);

  const { awsChannels, googleChannels, isLoading, isChannelModalOpen } = useSelector(selectChannelState);

  const data = [...mapAWSChannels(awsChannels), ...mapGoogleChannels(googleChannels)];

  useEffect(() => {
    actions.channels.fetchAwsChannels();
    actions.channels.fetchGoogleChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReloadIconClick = () => {
    actions.channels.fetchAwsChannels();
    actions.channels.fetchGoogleChannels();
  };

  const initializeChannelForm = () => {
    actions.channels.setChannelModalVisibilityState(true);
  };

  const onChannelModalClose = () => {
    actions.channels.setChannelModalVisibilityState(false);
  };

  return (
    <div style={{ padding: '0 50px' }}>
      <div style={{ textAlign: 'left', marginBottom: '20px' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <PlusCircleOutlined onClick={() => initializeChannelForm()} style={{ cursor: 'pointer' }} />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <ReloadOutlined onClick={handleReloadIconClick} style={{ cursor: 'pointer' }} />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {/* TODO: Add Search Filter */}
        {/* <Input
          suffix={<SearchOutlined />}
          defaultValue=""
          placeholder="Search Filter"
          onChange={handleSearchFilter}
          style={{ width: 200 }}
        /> */}
      </div>
      <Table columns={getColumns(actions)} dataSource={data} loading={isLoading} pagination={false} />
      <ChannelModal isModalOpen={isChannelModalOpen} onModalClose={onChannelModalClose} />
    </div>
  );
};

export default Channels;
