import { CamelotCode, CamelotWheel } from './CamelotWheel';

const downsampleArray = (audioIn: Float32Array, sampleRateIn: number, sampleRateOut: number) => {
  if (sampleRateOut === sampleRateIn) {
    return audioIn;
  }
  const sampleRateRatio = sampleRateIn / sampleRateOut;
  const newLength = Math.round(audioIn.length / sampleRateRatio);
  const result = new Float32Array(newLength);
  let offsetResult = 0;
  let offsetAudioIn = 0;

  console.log(`Downsampling to ${sampleRateOut} kHz...`);
  while (offsetResult < result.length) {
    const nextOffsetAudioIn = Math.round((offsetResult + 1) * sampleRateRatio);
    let accum = 0,
      count = 0;
    for (let i = offsetAudioIn; i < nextOffsetAudioIn && i < audioIn.length; i++) {
      accum += audioIn[i];
      count++;
    }
    result[offsetResult] = accum / count;
    offsetResult++;
    offsetAudioIn = nextOffsetAudioIn;
  }

  return result;
};

const preprocess = (audioBuffer: AudioBuffer) => {
  if (audioBuffer instanceof AudioBuffer) {
    const mono = monomix(audioBuffer);
    // downmix to mono, and downsample to 16kHz sr for essentia tensorflow models
    return downsampleArray(mono, audioBuffer.sampleRate, 16000);
  } else {
    throw new TypeError('Input to audio preprocessing is not of type AudioBuffer');
  }
};

const monomix = (buffer: AudioBuffer) => {
  // downmix to mono
  let monoAudio;
  if (buffer.numberOfChannels > 1) {
    console.log('mixing down to mono...');
    const leftCh = buffer.getChannelData(0);
    const rightCh = buffer.getChannelData(1);
    monoAudio = leftCh.map((sample, i) => 0.5 * (sample + rightCh[i]));
  } else {
    monoAudio = buffer.getChannelData(0);
  }

  return monoAudio;
};

declare global {
  interface Window {
    audioContext: AudioContext;
    EssentiaWASM: () => Promise<{
      EssentiaJS: {
        new (isWasm: boolean): {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          arrayToVector: (prepocessedAudio: Float32Array) => any;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          KeyExtractor: any;
        };
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      arrayToVector: (prepocessedAudio: Float32Array) => any;
    }>;
    webkitAudioContext: AudioContext;
  }
}

enum Scale {
  MAJOR = 'major',
  MINOR = 'minor',
}

export const getCamelotBasedMusicalKey = async (buffer: AudioBuffer) => {
  const prepocessedAudio = preprocess(buffer);
  const wasmModule = await window?.EssentiaWASM();
  const essentia = new wasmModule.EssentiaJS(false);
  essentia.arrayToVector = wasmModule.arrayToVector;

  const vectorSignal = essentia.arrayToVector(prepocessedAudio);
  const keyData = essentia.KeyExtractor(
    vectorSignal,
    true,
    4096,
    4096,
    12,
    3500,
    60,
    25,
    0.2,
    'bgate',
    16000,
    0.0001,
    440,
    'cosine',
    'hann',
  );

  let resolvedKey: CamelotCode | undefined;
  const camelotWheel = new CamelotWheel();

  if (keyData.scale === Scale.MINOR) {
    resolvedKey = camelotWheel.findCodeByMinorKey(keyData.key);
  } else {
    resolvedKey = camelotWheel.findCodeByMajorKey(keyData.key);
  }

  return resolvedKey;
};
