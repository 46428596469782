import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import Peaks, { PeaksInstance, PointClickEvent, PointMouseEvent, WaveformViewMouseEvent } from 'peaks.js';
import range from 'lodash/range';
import isNumber from 'lodash/isNumber';
import last from 'lodash/last';
import {
  Button,
  Divider,
  Form,
  FormInstance,
  InputNumber,
  message,
  Popover,
  Progress,
  Radio,
  RadioChangeEvent,
  Space,
} from 'antd';
import { BeatGridSettings } from 'containers/Tracks/types';
import { useSelector } from 'react-redux';
import { selectTrackState } from 'containers/Tracks/tracksSlice';
import { ActionsContext } from '../../Actions';
import { PlusOutlined, DeleteOutlined, MinusOutlined, ClearOutlined, EditOutlined } from '@ant-design/icons';
import { BeatGrid } from '../../models';
import { getSPB, getBpmFromSpb } from '../../utils';

interface AudioPlayerProps {
  onTrackLoadCallback: (duration: number, currentTrackToLoad: File) => void;
  onWaveformClick?: (currentTime: number) => void;
  trackToLoad?: File | string;
  bpm: number;
  form: FormInstance;
}

const DEFAULT_ZOOM_LEVEL_INDEX = 2;
const ZOOM_LEVELS = [32, 64, 128, 256, 512, 1024, 2048, 4096];
const BEAT_GRID_PREFIX = 'beat_grid-';
const TRACK_ENTRY_TOGGLE_COLORS = {
  ENABLED: 'blue',
  DISABLED: 'rgba(0, 0, 0, 0.63)',
};

export const AudioPlayer: React.FC<AudioPlayerProps> = (props) => {
  const { trackToLoad, onTrackLoadCallback, onWaveformClick, bpm, form } = props;
  const zoomViewContainerRef = useRef<HTMLDivElement>(null);
  const overviewContainerRef = useRef<HTMLDivElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [peaksInstance, setPeaksInstance] = useState<PeaksInstance | null>(null);

  const { actions } = useContext(ActionsContext);
  const { beatGrid, currentTrackTime, currentlyEditedTrack } = useSelector(selectTrackState);
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(50);
  const [segmentStartId, setSegmentStartId] = useState<string | undefined>();
  const [currentTrackDuration, setCurrentTrackDuration] = useState<number>();
  const [currentTimeRounded, setCurrentTimeRounded] = useState<number>();
  const [currentTrackProgress, setCurrentTrackProgress] = useState<number>();

  const beatGridSettings: BeatGridSettings[] = Form.useWatch('beat_grid_settings', { form, preserve: true });

  const handleZoomIn = () => {
    peaksInstance?.zoom.zoomIn();
  };
  const handleZoomOut = () => {
    peaksInstance?.zoom.zoomOut();
  };

  const handleStop = useCallback(() => {
    if (peaksInstance) {
      peaksInstance.player.pause();
      peaksInstance.player.seek(0);
      actions.tracks.setCurrentTrackTime(0);
    }
  }, [peaksInstance, actions.tracks]);

  const handlePlayPause = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (peaksInstance && audioRef.current) {
        if (audioRef.current.paused) {
          peaksInstance.player.play();
        } else {
          peaksInstance.player.pause();
        }
      }
    },
    [peaksInstance],
  );

  const handleAudioProcess = useCallback(
    (currentTime: number) => {
      if (currentTrackDuration) {
        const newCurrentTimeRounded = Number(currentTime.toFixed(6));
        const currentTrackProgress = ((newCurrentTimeRounded / currentTrackDuration) * 100).toFixed(0);

        setCurrentTimeRounded(newCurrentTimeRounded);
        setCurrentTrackProgress(Number(currentTrackProgress));
      }
    },
    [currentTrackDuration],
  );

  const handleOnClick = useCallback(
    (event: WaveformViewMouseEvent) => {
      if (peaksInstance) {
        const newCurrentTimeRounded = Number(event.time.toFixed(6));
        onWaveformClick?.(newCurrentTimeRounded);
        actions.tracks.setCurrentTrackTime(newCurrentTimeRounded);
      }
    },
    [actions.tracks, onWaveformClick, peaksInstance],
  );

  const handlePointDblClick = useCallback(
    (event: PointClickEvent) => {
      const beatGridIndexString = event.point.id?.replace(/^\D+/g, '');

      if (beatGridIndexString) {
        const beatGridIndex = parseInt(beatGridIndexString) - 1;
        const newBeatGrid = [...beatGrid];

        newBeatGrid[beatGridIndex] = new BeatGrid({
          ...newBeatGrid[beatGridIndex],
          is_track_entry: !newBeatGrid[beatGridIndex].is_track_entry,
        });

        if (JSON.stringify(beatGrid) !== JSON.stringify(newBeatGrid)) {
          actions.tracks.setBeatGrid(newBeatGrid);
        }
      }
    },
    [beatGrid, actions.tracks],
  );

  const handleOnPointDrag = useCallback(
    (event: PointMouseEvent) => {
      const beatGridIndexString = event.point.id?.replace(/^\D+/g, '');

      if (beatGridIndexString && beatGrid.length) {
        const beatGridIndex = parseInt(beatGridIndexString) - 1;

        const restorePoint = () => {
          event.point.update({
            time: beatGrid[beatGridIndex].start_time,
            labelText: event.point.labelText,
            color: event.point.color,
            editable: event.point.editable,
          });
        };

        message.error('Cannot drag beat grid specific items');
        restorePoint();
        return;
      }
    },
    [beatGrid],
  );

  const handlePlaybackSpeedChange = (event: RadioChangeEvent) => {
    if (audioRef.current) {
      const playbackRate = Number(event.target.value);
      audioRef.current.playbackRate = playbackRate;
      setPlaybackSpeed(event.target.value);
    }
  };

  const handleClearBeatGridClick = () => {
    if (peaksInstance) {
      peaksInstance.points.removeAll();
      actions.tracks.setBeatGrid([]);
      setSegmentStartId(undefined);
    }
  };

  useEffect(() => {
    const initPeaks = async (audioBuffer: AudioBuffer) => {
      if (zoomViewContainerRef.current && overviewContainerRef.current) {
        const options = {
          zoomview: {
            container: zoomViewContainerRef.current,
            // The offset in pixels edge of the visible waveform to trigger
            // auto-scroll
            autoScrollOffset: 100,
            // Mouse-wheel mode: either 'none' or 'scroll'
            wheelMode: 'scroll' as const,
          },
          overview: {
            container: overviewContainerRef.current,
          },
          mediaElement: audioRef.current as HTMLAudioElement,
          webAudio: {
            audioContext: window.audioContext,
            audioBuffer: audioBuffer,
          },
          zoomLevels: ZOOM_LEVELS,
        };

        Peaks.init(options, (err, peaks) => {
          if (err) {
            console.error('Failed to initialize Peaks.js', err);
            return;
          }
          if (peaks) {
            peaks.zoom.setZoom(DEFAULT_ZOOM_LEVEL_INDEX);
            const trackDuration = peaks.player.getDuration();
            onTrackLoadCallback(trackDuration, trackToLoad as File);
            setCurrentTrackDuration(trackDuration);
            setPeaksInstance(peaks);
          }
        });
      }
    };

    if (trackToLoad) {
      const loadTrack = async () => {
        let arrayBuffer: ArrayBuffer;

        try {
          if (typeof trackToLoad === 'string') {
            const response = await fetch(trackToLoad);
            arrayBuffer = await response.arrayBuffer();

            if (audioRef.current) {
              audioRef.current.src = trackToLoad;
            }
          } else {
            arrayBuffer = await trackToLoad.arrayBuffer();
            if (audioRef.current) {
              audioRef.current.src = URL.createObjectURL(new Blob([trackToLoad]));
            }
          }

          const audioBuffer = await window.audioContext.decodeAudioData(arrayBuffer);
          await initPeaks(audioBuffer);
        } catch (err) {
          console.error('FATAL ERROR > loadTrack:', err);
        }
      };

      loadTrack();
    }

    return () => {
      if (peaksInstance) {
        peaksInstance.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackToLoad]);

  useEffect(() => {
    if (peaksInstance) {
      peaksInstance.on('player.timeupdate', handleAudioProcess);
      peaksInstance.on('zoomview.click', handleOnClick);
      peaksInstance.on('points.dblclick', handlePointDblClick);
      peaksInstance.on('points.dragend', handleOnPointDrag);
    }

    // Cleanup event listeners on unmount or when dependencies change
    return () => {
      peaksInstance?.off('player.timeupdate', handleAudioProcess);
      peaksInstance?.off('zoomview.click', handleOnClick);
      peaksInstance?.off('points.dblclick', handlePointDblClick);
      peaksInstance?.off('points.dragend', handleOnPointDrag);
    };
  }, [peaksInstance, handleAudioProcess, handleOnClick, handlePointDblClick, handleOnPointDrag]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check if the pressed key is spacebar
      if (event.code === 'Space' || event.key === ' ') {
        // Check if the active element is not an input or textarea
        if (!['INPUT', 'TEXTAREA', 'BUTTON'].includes(document?.activeElement?.tagName as string)) {
          event.preventDefault(); // Prevent scrolling
          if (peaksInstance && audioRef.current) {
            if (audioRef.current.paused) {
              peaksInstance.player.play();
            } else {
              peaksInstance.player.pause();
            }
          }
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [peaksInstance]);

  useEffect(() => {
    if (beatGridSettings && peaksInstance) {
      let newBeatGrid: BeatGrid[] = [];
      let lastRangeGrid: number[] = [];

      beatGridSettings.forEach((beatGridSetting) => {
        const grid = range(beatGridSetting.start_time, beatGridSetting.end_time, beatGridSetting.spb);

        newBeatGrid = newBeatGrid.concat(
          grid.map((time, index) => {
            return new BeatGrid({
              beat: lastRangeGrid.length + 0 + index + 1,
              start_time: time,
              spb: beatGridSetting.spb,
              is_track_entry: false,
            });
          }),
        );

        lastRangeGrid = grid;
      });

      if (JSON.stringify(beatGrid) !== JSON.stringify(newBeatGrid)) {
        actions.tracks.setBeatGrid(newBeatGrid);
      }
    } else {
      if (currentlyEditedTrack?.beat_grid) {
        actions.tracks.setBeatGrid(currentlyEditedTrack.beat_grid);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beatGridSettings, currentlyEditedTrack]);

  useEffect(() => {
    if (peaksInstance && beatGrid) {
      peaksInstance.points.removeAll();

      const beatGridToBeRendered = beatGrid.map((gridItem) => {
        return {
          id: `${BEAT_GRID_PREFIX}${gridItem.beat}`,
          time: gridItem.start_time + 0,
          labelText: `${gridItem.beat + 0}`,
          color: gridItem.is_track_entry ? TRACK_ENTRY_TOGGLE_COLORS.ENABLED : TRACK_ENTRY_TOGGLE_COLORS.DISABLED,
          editable: true,
        };
      });

      peaksInstance.points.add(beatGridToBeRendered);
    }
  }, [peaksInstance, beatGrid]);

  return (
    <div>
      <div className="waveform-container">
        <div id="zoom-container" ref={zoomViewContainerRef} style={{ width: '100%', height: '225px' }} />
      </div>
      <div className="waveform-container">
        <div id="overview-container" ref={overviewContainerRef} style={{ width: '100%', height: '100px' }} />
      </div>
      <audio ref={audioRef}></audio>
      <br />
      <Button style={{ outline: 'none' }} onClick={handlePlayPause}>
        Play/Pause
      </Button>
      &nbsp;&nbsp;&nbsp;
      <Button style={{ outline: 'none' }} onClick={handleStop}>
        Stop
      </Button>
      &nbsp;&nbsp;&nbsp;
      <Radio.Group defaultValue="" buttonStyle="solid">
        <Radio.Button value="-" onClick={handleZoomOut}>
          <MinusOutlined /> Zoom
        </Radio.Button>
        <Radio.Button value="+" onClick={handleZoomIn}>
          <PlusOutlined /> Zoom
        </Radio.Button>
      </Radio.Group>
      &nbsp;&nbsp;&nbsp;
      <Radio.Group value={playbackSpeed} onChange={handlePlaybackSpeedChange}>
        <Radio.Button value="0.25">0.25x</Radio.Button>
        <Radio.Button value="0.5">0.5x</Radio.Button>
        <Radio.Button value="0.75">0.75x</Radio.Button>
        <Radio.Button value="1">Normal</Radio.Button>
        <Radio.Button value="1.25">1.25x</Radio.Button>
        <Radio.Button value="1.5">1.5x</Radio.Button>
      </Radio.Group>
      &nbsp;&nbsp;&nbsp;
      <Progress style={{ paddingLeft: '10px' }} type="circle" percent={currentTrackProgress} size={40} />
      <div>
        <p>Current Time: {currentTimeRounded}</p>
      </div>
      <Divider>Beat Grid</Divider>
      <div style={{ marginBottom: '20px' }}>
        <Form.List name="beat_grid_settings">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                  <Form.Item
                    {...restField}
                    label="Start Time"
                    name={[name, 'start_time']}
                    // tooltip={START_TIME_TOOLTIP}
                    rules={[{ required: true, message: 'Missing Start Time' }]}
                  >
                    <InputNumber placeholder="Start Time" min={0} max={3600} precision={4} step={0.001} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label="End Time"
                    name={[name, 'end_time']}
                    // tooltip={START_TIME_TOOLTIP}
                    rules={[{ required: true, message: 'Missing End Time' }]}
                  >
                    <InputNumber placeholder="End Time" min={0} max={3600} precision={4} step={0.001} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label="Seconds Per Beat"
                    name={[name, 'spb']}
                    // tooltip={START_TIME_TOOLTIP}
                    rules={[{ required: true, message: 'Missing Seconds Per Beat' }]}
                  >
                    <InputNumber placeholder="Seconds Per Beat" min={0} max={3600} precision={6} step={0.001} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label="Beats Per Minute"
                    // name={[name, 'bpm']}
                  >
                    <InputNumber
                      disabled
                      value={getBpmFromSpb(beatGridSettings[index].spb)}
                      placeholder="Beats Per Minute"
                      min={30}
                      max={400}
                      precision={6}
                      step={0.001}
                    />
                  </Form.Item>
                  <Popover
                    content={() => {
                      const handlePopOverButtonChange = (val: string) => {
                        const newBeatGridSettings = [
                          ...((form.getFieldValue('beat_grid_settings') || []) as BeatGridSettings[]),
                        ];

                        newBeatGridSettings[index] = {
                          ...newBeatGridSettings[index],
                          spb: getBpmFromSpb(Number(Number(val).toFixed(6))) || newBeatGridSettings[index].spb,
                        };

                        if (!newBeatGridSettings[index].spb || newBeatGridSettings[index].spb <= 0) {
                          return;
                        }

                        form.setFieldsValue({
                          beat_grid_settings: newBeatGridSettings,
                        });
                      };

                      return (
                        <InputNumber
                          style={{ width: '100%' }}
                          placeholder="Beats Per Minute"
                          precision={6}
                          step={0.05}
                          onChange={(val) => {
                            if (val) {
                              handlePopOverButtonChange(val.toString());
                            }
                          }}
                          onBlur={(event) => {
                            const val = event.target.value;
                            handlePopOverButtonChange(val);
                          }}
                        />
                      );
                    }}
                    title="Set By BPM"
                    trigger="click"
                    style={{ textAlign: 'center' }}
                  >
                    <EditOutlined />
                  </Popover>
                  <DeleteOutlined
                    onClick={() => {
                      remove(name);
                    }}
                  />
                </Space>
              ))}
              <Form.Item>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <Button
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                      handleClearBeatGridClick();
                      remove(range(0, fields.length + 1, 1));
                    }}
                  >
                    <ClearOutlined /> Clear Beat Grid
                  </Button>
                  <Button
                    type={!segmentStartId ? 'default' : 'dashed'}
                    onClick={() => {
                      if (peaksInstance) {
                        if (isNumber(currentTrackTime) && !segmentStartId) {
                          const segmentStartPoint = peaksInstance.points.add({
                            time: currentTrackTime,
                            labelText: 'start',
                            color: 'orange',
                            editable: true,
                          });

                          setSegmentStartId(segmentStartPoint.id);
                        } else if (segmentStartId && currentTrackDuration) {
                          const firstSegmentPoint = peaksInstance.points.getPoint(segmentStartId);
                          if (firstSegmentPoint) {
                            add({
                              start_time: isNumber(firstSegmentPoint?.time)
                                ? firstSegmentPoint?.time
                                : currentTrackTime || 0,
                              end_time: currentTrackTime || currentTrackDuration || 0,
                              spb: last(beatGridSettings)?.spb || getSPB(bpm),
                            });
                            setSegmentStartId(undefined);
                          }
                        }
                      }
                    }}
                  >
                    <PlusOutlined /> Add Beat Grid {!segmentStartId ? 'Start' : 'End'} Point
                  </Button>
                </div>

                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
    </div>
  );
};
