import { useEffect, useState } from 'react';
import { FirebaseService } from 'utils/firebase';

export const useUserCustomClaims = () => {
  const [userCustomClaims, setUserCustomClaims] = useState({
    isAdmin: false,
    isSuperAdmin: false,
  });

  useEffect(() => {
    const fetchAndSetUserCustomClaims = async () => {
      const claims = await FirebaseService.getCurrentUserCustomClaims();
      setUserCustomClaims({
        isAdmin: claims?.admin,
        isSuperAdmin: claims?.super_admin,
      });
    };
    fetchAndSetUserCustomClaims();
  }, [setUserCustomClaims]);

  return userCustomClaims;
};
