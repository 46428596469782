import { createSlice } from '@reduxjs/toolkit';
import { TablePaginationConfig, message } from 'antd';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Epic, ofType } from 'redux-observable';
import { switchMap, catchError } from 'rxjs/operators';
import type { RootAction, RootState } from '../../services/ReduxService/store';
import { VybeCollection, VybeCollectionsResponse, VybeCollectionCreateObject } from './types';
import { ApiService } from '../../utils/ApiService';
import { of, concat, from, debounceTime } from 'rxjs';
import { FilterValue, Key, SorterResult } from 'antd/es/table/interface';
import { Track, TracksResponse } from 'containers/Tracks/types';
import { AxiosError } from 'axios';

// Define a type for the slice state
export interface VybeCollectionsState {
  vybeCollections: VybeCollection[];
  trackSearchResults: Track[];
  isLoading: boolean;
  searchString: string;
  trackSearchString: string;
  selectedRowKeys: Key[];
  pagination: TablePaginationConfig;
  filters: Record<string, FilterValue | null>;
  sorter: SorterResult<VybeCollection>;
  isVybeCollectionModalOpen: boolean;
  currentlyEditedVybeCollection?: VybeCollection;
}

// Define the initial state using that type
export const initialState: VybeCollectionsState = {
  vybeCollections: [],
  trackSearchResults: [],
  isLoading: false,
  searchString: '',
  trackSearchString: '',
  selectedRowKeys: [],
  pagination: {
    current: 1,
    pageSize: 50,
    total: 0,
    position: ['topRight', 'bottomRight'],
  },
  filters: {},
  sorter: {
    column: undefined,
    columnKey: undefined,
    field: 'created_at',
    order: undefined,
  },
  isVybeCollectionModalOpen: false,
  currentlyEditedVybeCollection: undefined,
};

export const vybeCollectionSlice = createSlice({
  name: 'vybeCollections',
  initialState,
  reducers: {
    fetchVybeCollections: (state) => state,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteVybeCollections: (state, action: PayloadAction<number[]>) => state,
    saveVybeCollection: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<Partial<VybeCollectionCreateObject>>,
    ) => state,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateVybeCollection: (state, action: PayloadAction<Partial<VybeCollection>>) => state,
    setVybeCollections: (state, action: PayloadAction<VybeCollection[]>) => {
      state.vybeCollections = action.payload;
    },
    setTrackSearchResults: (state, action: PayloadAction<Track[]>) => {
      state.trackSearchResults = action.payload;
    },
    setLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSearchString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    setTrackSearchString: (state, action: PayloadAction<string>) => {
      state.trackSearchString = action.payload;
    },
    setSelectedRowKeys: (state, action: PayloadAction<Key[]>) => {
      state.selectedRowKeys = action.payload;
    },
    setPagination: (state, action: PayloadAction<TablePaginationConfig>) => {
      state.pagination = action.payload;
    },
    setSorter: (state, action: PayloadAction<SorterResult<VybeCollection>>) => {
      state.sorter = {
        ...state.sorter,
        ...action.payload,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any;
    },
    setVybeCollectionModalVisibilityState: (state, action: PayloadAction<boolean>) => {
      state.isVybeCollectionModalOpen = action.payload;
    },
    setCurrentlyEditedVybeCollection: (state, action: PayloadAction<VybeCollection | undefined>) => {
      state.currentlyEditedVybeCollection = action.payload;
    },
  },
});

export const vybeCollectionActions = vybeCollectionSlice.actions;
export const {
  fetchVybeCollections,
  deleteVybeCollections,
  saveVybeCollection,
  updateVybeCollection,
  setVybeCollections,
  setTrackSearchResults,
  setLoadingState,
  setSearchString,
  setTrackSearchString,
  setSelectedRowKeys,
  setPagination,
  setSorter,
  setVybeCollectionModalVisibilityState,
  setCurrentlyEditedVybeCollection,
} = vybeCollectionSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectVybeCollectionState = (state: RootState) => state.vybeCollections;

export default vybeCollectionSlice.reducer;

export type SortOrder = 'asc' | 'desc';

export const onTrackSearchEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(setTrackSearchString.type),
    debounceTime(350),
    switchMap(() => {
      const filterOptions = {
        query: state$.value.vybeCollections.trackSearchString,
      };

      if (!filterOptions.query) {
        return of(setTrackSearchResults([]));
      }

      return concat(
        of(setLoadingState(true)),
        from(ApiService.getAllCollectionValues<TracksResponse>('tracks', filterOptions).then((data) => data.data)).pipe(
          switchMap((data) => {
            const trackSearchResultIds = state$.value.vybeCollections.trackSearchResults.map((track) => track.id);
            const filteredTracks = data.data.filter((track) => !trackSearchResultIds.includes(track.id));
            return [setTrackSearchResults(filteredTracks)];
          }),
        ),
        of(setLoadingState(false)),
      );
    }),
    catchError((err: AxiosError<{ error: string }>) => {
      console.error('FATAL ERROR: err > vybeCollection > onTrackSearchEpic: ', err);
      message.error(err.response?.data?.error || err.message);
      return of(setLoadingState(false));
    }),
  );

export const onFetchVybeCollectionsEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(fetchVybeCollections.type),
    switchMap(() => {
      const filterOptions = {
        page: state$.value.vybeCollections.pagination.current,
        limit: state$.value.vybeCollections.pagination.pageSize,
        order_by: state$.value.vybeCollections.sorter.field,
        sort: state$.value.vybeCollections.sorter.order === 'ascend' ? 'asc' : 'desc',
        query: state$.value.vybeCollections.searchString,
      };

      return concat(
        of(setLoadingState(true)),
        from(
          ApiService.getAllCollectionValues<VybeCollectionsResponse>('vybe-collections', filterOptions).then(
            (data) => data.data,
          ),
        ).pipe(
          switchMap((data) => {
            return [
              setVybeCollections(data.data),
              setPagination({ ...state$.value.vybeCollections.pagination, total: data.meta.total_count }),
            ];
          }),
        ),
        of(setLoadingState(false)),
      );
    }),
    catchError((err: AxiosError<{ error: string }>) => {
      console.error('FATAL ERROR: err > vybeCollections > onFetchVybeCollectionsEpic: ', err);
      message.error(err.response?.data?.error || err.message);
      return of(setLoadingState(false));
    }),
  );

// export const onSearchStringChangeEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
//   action$.pipe(
//     ofType(setSearchString.type),
//     debounceTime(350),
//     switchMap(() => of(fetchTracks())),
//     catchError((err: Error) => {
//       console.error('FATAL ERROR: err > tracks > onSearchStringChangeEpic: ', err);
//       message.error(err.message);
//       return of(setLoadingState(false));
//     })
//   );

export const onDeleteSelectedVybeCollectionRowsEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    ofType(deleteVybeCollections.type),
    switchMap((action) => {
      const ids = action.payload;
      return concat(
        of(setLoadingState(true)),
        from(ApiService.doGenericDeleteMany('vybe-collections', ids).then((data) => data.data)).pipe(
          switchMap(() => of(fetchVybeCollections())),
        ),
        of(setLoadingState(false)),
      );
    }),
    catchError((err: AxiosError<{ error: string }>) => {
      console.error('FATAL ERROR: err > vybeCollections > onDeleteSelectedVybeCollectionRowsEpic: ', err);
      message.error(err.response?.data?.error || err.message);
      return of(setLoadingState(false));
    }),
  );

export const onVybeCollectionEditModalCloseEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    ofType(setVybeCollectionModalVisibilityState.type),
    switchMap(({ payload }) => {
      const isHidingModal = !payload;
      return isHidingModal ? of(setCurrentlyEditedVybeCollection(undefined)) : of();
    }),
    catchError((err: Error) => {
      console.error('FATAL ERROR: err > vybeCollections > onVybeCollectionEditModalCloseEpic: ', err);
      message.error(err.message);
      return of(setLoadingState(false));
    }),
  );

export const onSaveVybeCollectionEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    ofType(saveVybeCollection.type),
    switchMap((action) => {
      const vybeCollection = action.payload;
      return concat(
        of(setLoadingState(true)),
        from(ApiService.doGenericPost('vybe-collections', vybeCollection)).pipe(
          switchMap(() => {
            message.success('VybeCollection saved successfully!');
            // Only close the modal on successful vybeCollection upload.
            return of(fetchVybeCollections(), setVybeCollectionModalVisibilityState(false));
          }),
          catchError((err: AxiosError<{ error: string }>) => {
            console.error('FATAL ERROR: err > vybeCollections > onSaveVybeCollectionEpic: ', err);
            message.error(err.response?.data?.error || err.message);
            // Ensure vybeCollectionModalVisibilityState remains true on error
            return of(setLoadingState(false), setVybeCollectionModalVisibilityState(true));
          }),
        ),
      );
    }),
    catchError((err: Error) => {
      console.error('FATAL ERROR: err > vybeCollections > onSaveVybeCollectionEpic: ', err);
      message.error(err.message);
      // Ensure vybeCollectionModalVisibilityState remains true on error
      return of(setLoadingState(false), setVybeCollectionModalVisibilityState(true));
    }),
  );

export const onUpdateVybeCollectionEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    ofType(updateVybeCollection.type),
    switchMap((action) => {
      const vybeCollection = action.payload;
      if (!vybeCollection.id && vybeCollection.id !== 0) {
        throw new Error('VybeCollection ID is required to update a vybeCollection!');
      }

      return concat(
        of(setLoadingState(true)),
        from(ApiService.doGenericPatch('vybe-collections', vybeCollection.id, vybeCollection)).pipe(
          switchMap(() => {
            message.success('VybeCollection updated successfully!');
            // Only close the modal on successful vybeCollection upload.
            return of(fetchVybeCollections(), setVybeCollectionModalVisibilityState(false));
          }),
          catchError((err: AxiosError<{ error: string }>) => {
            console.error('FATAL ERROR: err > vybeCollections > onUpdateVybeCollectionEpic: ', err);
            message.error(err.response?.data?.error || err.message);
            // Ensure vybeCollectionModalVisibilityState remains true on error
            return of(setLoadingState(false), setVybeCollectionModalVisibilityState(true));
          }),
        ),
      );
    }),
    catchError((err: Error) => {
      console.error('FATAL ERROR: err > vybeCollections > onUpdateVybeCollectionEpic: ', err);
      message.error(err.message);
      // Ensure vybeCollectionModalVisibilityState remains true on error
      return of(setLoadingState(false), setVybeCollectionModalVisibilityState(true));
    }),
  );
