import axios, { AxiosResponse } from 'axios';
import isEmpty from 'lodash/isEmpty';
import { FirebaseService } from './firebase';
import { BASE_API_URL } from './config';

export class ApiService {
  static async getTokenizedHeader() {
    const token = await FirebaseService.getAuth().currentUser?.getIdToken();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
  }

  static async getCollectionValueById(collectionName: string, id: number) {
    const headerObject = await ApiService.getTokenizedHeader();

    return axios.get(`${BASE_API_URL}/${collectionName}/${id}`, headerObject);
  }

  static async getAllCollectionValues<T>(collectionName: string, options = {}): Promise<AxiosResponse<T>> {
    const stringifiedQuery = !isEmpty(options) ? '?' + new URLSearchParams(options).toString() : '';
    const headerObject = await ApiService.getTokenizedHeader();

    return axios.get(`${BASE_API_URL}/${collectionName}${stringifiedQuery}`, headerObject);
  }

  static async getCollectionCount(collectionName: string) {
    const headerObject = await ApiService.getTokenizedHeader();

    return axios.get(`${BASE_API_URL}/${collectionName}/count`, headerObject);
  }

  static async doGenericPost(collectionName: string, data = {}) {
    const headerObject = await ApiService.getTokenizedHeader();

    return axios.post(`${BASE_API_URL}/${collectionName}`, data, headerObject);
  }

  static async doGenericPatch(collectionName: string, id: number, data = {}) {
    const headerObject = await ApiService.getTokenizedHeader();

    return axios.patch(`${BASE_API_URL}/${collectionName}/${id}`, data, headerObject);
  }

  static async doGenericDelete(collectionName: string) {
    const headerObject = await ApiService.getTokenizedHeader();

    const options = Object.assign({}, headerObject);

    return axios.delete(`${BASE_API_URL}/${collectionName}`, options);
  }

  static async doGenericDeleteMany(collectionName: string, ids: number[]) {
    const headerObject = await ApiService.getTokenizedHeader();

    const options = Object.assign({}, headerObject, { data: { ids } });

    return axios.delete(`${BASE_API_URL}/${collectionName}`, options);
  }
}
