import { PlayCircleOutlined, PauseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Popconfirm, Space, Tag, message } from 'antd';
import type { ColumnGroupType, ColumnType } from 'antd/es/table';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { actionCreators } from 'Actions';
import { Platform } from '../types';

interface DataType {
  id: string;
  name: string;
  platform: string;
  state: string;
}

export const getColumns = function (actions: typeof actionCreators) {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Stream Url',
      dataIndex: 'streamUrl',
      key: 'streamUrl',
      render: (url) => <a onClick={() => window.open(url, '_blank')}>{url}</a>,
    },
    {
      title: 'Input Url',
      dataIndex: 'inputUrl',
      key: 'inputUrl',
      render: (url) => (
        <CopyToClipboard text={url} onCopy={() => message.info('URL Copied!', 1)}>
          <a>{url}</a>
        </CopyToClipboard>
      ),
    },
    {
      title: 'Platform',
      key: 'platform',
      dataIndex: 'platform',
      render: (platform) => {
        let color = 'green';

        switch (true) {
          case platform === Platform.AWS: {
            color = 'volcano';
            break;
          }
          case platform === Platform.GOOGLE: {
            color = 'geekblue';
            break;
          }
          default: {
            color = 'green';
            break;
          }
        }

        return (
          <Tag color={color} key={platform}>
            {platform.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, { id, platform }) => {
        const handleChannelStart = () => {
          switch (true) {
            case platform === Platform.AWS: {
              actions.channels.startAwsChannel(id);
              break;
            }
            case platform === Platform.GOOGLE: {
              const resolvedGoogleId = id.replace('-channel', '').replace(/-/g, '_').replace(/ /g, '').trim();
              actions.channels.startGoogleChannel(resolvedGoogleId);
              break;
            }
            default: {
              break;
            }
          }
        };

        const handleChannelStop = () => {
          switch (true) {
            case platform === Platform.AWS: {
              actions.channels.stopAwsChannel(id);
              break;
            }
            case platform === Platform.GOOGLE: {
              const resolvedGoogleId = id.replace('-channel', '').replace(/-/g, '_').replace(/ /g, '').trim();
              actions.channels.stopGoogleChannel(resolvedGoogleId);
              break;
            }
            default: {
              break;
            }
          }
        };

        const handleChannelDelete = () => {
          switch (true) {
            case platform === Platform.AWS: {
              actions.channels.deleteAwsChannel(id);
              break;
            }
            case platform === Platform.GOOGLE: {
              const resolvedGoogleId = id.replace('-channel', '').replace(/-/g, '_').replace(/ /g, '').trim();
              actions.channels.deleteGoogleChannel(resolvedGoogleId);
              break;
            }
            default: {
              break;
            }
          }
        };

        return (
          <Space size="middle">
            <PlayCircleOutlined title="Play" onClick={handleChannelStart} />
            <PauseCircleOutlined title="Stop" onClick={handleChannelStop} />
            <Popconfirm
              title="Are you sure?"
              onConfirm={handleChannelDelete}
              placement="right"
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined title="Delete" style={{ cursor: 'pointer' }} />
            </Popconfirm>
          </Space>
        );
      },
    },
  ] as (ColumnGroupType<DataType> | ColumnType<DataType>)[];
};
