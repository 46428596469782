import React, { useContext } from 'react';
import { Modal, Input, List, Card } from 'antd';
import { ActionsContext } from '../../Actions';
import { useSelector } from 'react-redux';
import { selectTrackState } from 'containers/Tracks/tracksSlice';
import { SpotifyTrackResult } from 'containers/Tracks/types';

const { Meta } = Card;

interface SpotifyModalProps {
  isModalOpen?: boolean;
  onModalClose?: () => void;
  onSpotifyTrackSelect?: (spotifyTrack: SpotifyTrackResult) => void;
}

export const SpotifyModal: React.FC<SpotifyModalProps> = (props) => {
  const { isModalOpen, onModalClose, onSpotifyTrackSelect } = props;
  const { actions } = useContext(ActionsContext);
  const { spotifyTrackResults, isLoading, spotifySearchString } = useSelector(selectTrackState);

  const handleSpotifySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = e.target.value || '';
    actions.tracks.setSpotifySearchString(searchString);
  };

  const handleModalClose = () => {
    actions.tracks.setSpotifySearchString('');
    onModalClose?.();
  };

  return (
    <>
      <Modal title="Load Track Details From Spotify" open={isModalOpen} onCancel={handleModalClose} footer={null}>
        <p>Search for track:</p>
        <Input value={spotifySearchString} onChange={handleSpotifySearch} />
        <div>
          <p>Search Results:</p>
        </div>
        <div>
          <List
            itemLayout="vertical"
            size="large"
            loading={isLoading}
            dataSource={spotifyTrackResults}
            style={{ textAlign: 'center', margin: 'auto' }}
            renderItem={(item) => (
              <List.Item style={{ textAlign: 'center' }} key={item?.spotify_id}>
                <Card
                  onClick={() => onSpotifyTrackSelect?.(item)}
                  hoverable
                  style={{ width: '100%' }}
                  cover={<img alt={item.title} src={item.album_art_url} />}
                >
                  <Meta title={item.title} description={item.artist} />
                  <p>{item.release_date}</p>
                  <Meta description={item.album} />
                  {item.preview_url && (
                    <div style={{ marginTop: 10 }}>
                      <audio controls>
                        <source src={item.preview_url} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                  )}
                </Card>
              </List.Item>
            )}
          />
        </div>
      </Modal>
    </>
  );
};

export default SpotifyModal;
