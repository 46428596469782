import { createContext } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
//import All Action Creators
import { trackActions } from './containers/Tracks/tracksSlice';
import { channelActions } from './containers/Channels/channelsSlice';
import { vybeCollectionActions } from './containers/VybeCollections/vybeCollectionsSlice';
import { store } from './services/ReduxService/store';

//Then Add Action Creators here
export const actionCreators = {
  //   app: appActions,
  //   login: loginActions,
  //   dataList: dataListActions,
  tracks: trackActions,
  channels: channelActions,
  vybeCollections: vybeCollectionActions,
  //   mixes: mixesActions,
  //   bulkTrackUpload: bulkTrackUploadActions,
};

const dispatch = store.dispatch;

export const boundedActions = Object.entries(actionCreators).reduce(
  (acc, [key, value]) => {
    const nestedObj = Object.entries(value).reduce((nestedAcc, [nestedKey, nestedValue]) => {
      return { ...nestedAcc, [nestedKey]: bindActionCreators(nestedValue, dispatch) };
    }, {});
    return { ...acc, [key]: nestedObj };
  },
  {} as typeof actionCreators,
);

interface ActionsContextType {
  actions: typeof actionCreators;
}
export const ActionsContext = createContext<ActionsContextType>({
  actions: actionCreators,
});

// //requires the 'dispatch' value from the 'connected' components 'this.props'
//TODO: use reduce here
// const getBoundedActions = () => {
//   const boundActionCreators = {};
//   forEach(actionCreators, (actionCreator, key) => {
//     boundActionCreators[key] = bindActionCreators(actionCreator, dispatch);
//   });
//   return boundActionCreators;
// };

// export default getBoundedActions;
