import { FC } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { FormValues } from '../../../types';

const FormItem = Form.Item;

interface LoginFormProps {
  handleLoginSubmit: (values: FormValues) => void;
}

const LoginForm: FC<LoginFormProps> = ({ handleLoginSubmit }) => {
  const [form] = Form.useForm<FormValues>();

  return (
    <Form form={form} name="login-form" onFinish={handleLoginSubmit} className="login-form">
      <FormItem
        name="email"
        rules={[{ required: true, min: 3, max: 75, message: 'Please input your email!', type: 'email' }]}
      >
        <Input prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
      </FormItem>
      <FormItem name="password" rules={[{ required: true, min: 3, max: 75, message: 'Please input your Password!' }]}>
        <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
      </FormItem>
      <FormItem name="remember" initialValue={true} valuePropName="checked">
        <Checkbox>Remember me</Checkbox>
      </FormItem>
      <Button type="primary" htmlType="submit" className="login-form-button">
        Log in
      </Button>
    </Form>
  );
};

export default LoginForm;
