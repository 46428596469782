export type CamelotCode =
  | '1A'
  | '1B'
  | '2A'
  | '2B'
  | '3A'
  | '3B'
  | '4A'
  | '4B'
  | '5A'
  | '5B'
  | '6A'
  | '6B'
  | '7A'
  | '7B'
  | '8A'
  | '8B'
  | '9A'
  | '9B'
  | '10A'
  | '10B'
  | '11A'
  | '11B'
  | '12A'
  | '12B';

interface CamelotKeyObject {
  major?: string[];
  minor?: string[];
  code: CamelotCode;
}

export class CamelotWheel {
  private wheel: CamelotKeyObject[] = [
    // Majors
    { major: ['B'], code: '1B' },
    { major: ['F#', 'Gb'], code: '2B' },
    { major: ['C#', 'Db'], code: '3B' },
    { major: ['G#', 'Ab'], code: '4B' },
    { major: ['D#', 'Eb'], code: '5B' },
    { major: ['A#', 'Bb'], code: '6B' },
    { major: ['F'], code: '7B' },
    { major: ['C'], code: '8B' },
    { major: ['G'], code: '9B' },
    { major: ['D'], code: '10B' },
    { major: ['A'], code: '11B' },
    { major: ['E'], code: '12B' },

    // Minors
    { minor: ['F'], code: '4A' },
    { minor: ['C'], code: '5A' },
    { minor: ['G'], code: '6A' },
    { minor: ['D'], code: '7A' },
    { minor: ['A'], code: '8A' },
    { minor: ['E'], code: '9A' },
    { minor: ['B'], code: '10A' },
    { minor: ['F#', 'Gb'], code: '11A' },
    { minor: ['C#', 'Db'], code: '12A' },
    { minor: ['Ab', 'Bb'], code: '1A' },
    { minor: ['D#', 'Eb'], code: '2A' },
    { minor: ['G#', 'Ab'], code: '3A' },
  ];

  public findCodeByMajorKey(majorKey: string): CamelotCode | undefined {
    const entry = this.wheel.find((key) => key?.major?.includes(majorKey));
    return entry ? entry.code : undefined;
  }

  public findCodeByMinorKey(minorKey: string): CamelotCode | undefined {
    const entry = this.wheel.find((key) => key?.minor?.includes(minorKey));
    return entry ? entry.code : undefined;
  }

  public findKeyByCode(code: CamelotCode): { major?: string; minor?: string } | undefined {
    const entry = this.wheel.find((key) => key.code === code);
    return entry ? { major: entry?.major?.[0], minor: entry?.minor?.[0] } : undefined;
  }
}
