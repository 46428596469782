import { useMemo } from 'react';
import { message } from 'antd';
import { FormValues } from '../../types';
import { FirebaseService } from '../../utils/firebase';
import LoginForm from './LoginForm';
import { Image } from 'antd';

//Styles
import './login.css';

export const Login = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const handleLoginSubmit = async (values: FormValues) => {
    const { email, password } = values;

    try {
      await FirebaseService.signInWithEmailAndPassword(email, password);
    } catch (err) {
      const error = err as Error;
      console.error(' FATAL ERROR > ', err);

      messageApi.error(error.message);
    }
  };

  const NeuVybeLogoTypeBW = useMemo(() => new URL('../../assets/neuvybe_logotype_bw.png', import.meta.url), []);

  return (
    <div className="login">
      <div className="login-container">
        {contextHolder}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '30px',
            flexDirection: 'column',
          }}
        >
          <Image style={{ pointerEvents: 'none' }} preview={false} width={200} src={NeuVybeLogoTypeBW.toString()} />
        </div>
        <LoginForm handleLoginSubmit={handleLoginSubmit} />
      </div>
    </div>
  );
};
