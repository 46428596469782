import { useContext, useEffect } from 'react';
import { Input, Table } from 'antd';
import { useSelector } from 'react-redux';
import { ActionsContext } from '../../Actions';
import { VybeCollectionModal } from './VybeCollectionModal';
import { getColumns } from './helpers';
import { PlusCircleOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { selectVybeCollectionState } from './vybeCollectionsSlice';

export const VybeCollections = () => {
  const { actions } = useContext(ActionsContext);
  const { vybeCollections, isLoading, isVybeCollectionModalOpen } = useSelector(selectVybeCollectionState);

  useEffect(() => {
    actions.vybeCollections.fetchVybeCollections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReloadIconClick = () => {
    actions.vybeCollections.fetchVybeCollections();
  };

  const initializeChannelForm = () => {
    actions.vybeCollections.setVybeCollectionModalVisibilityState(true);
  };

  const onVybeCollectionModalClose = () => {
    actions.vybeCollections.setVybeCollectionModalVisibilityState(false);
  };

  const handleSearchFilter = () => {
    // actions.channels.setChannelModalVisibilityState(true);
  };

  return (
    <div style={{ padding: '0 50px' }}>
      <div style={{ textAlign: 'left', marginBottom: '20px' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <PlusCircleOutlined onClick={() => initializeChannelForm()} style={{ cursor: 'pointer' }} />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <ReloadOutlined onClick={handleReloadIconClick} style={{ cursor: 'pointer' }} />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Input
          suffix={<SearchOutlined />}
          defaultValue=""
          placeholder="Search Filter"
          onChange={handleSearchFilter}
          style={{ width: 200 }}
        />
      </div>
      <Table
        rowKey="id"
        columns={getColumns(actions)}
        dataSource={vybeCollections}
        loading={isLoading}
        pagination={false}
      />
      {isVybeCollectionModalOpen && (
        <VybeCollectionModal isModalOpen={isVybeCollectionModalOpen} onModalClose={onVybeCollectionModalClose} />
      )}
    </div>
  );
};

export default VybeCollections;
