import { Layout } from 'antd';
const { Footer } = Layout;

export const MainFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Footer style={{ textAlign: 'center', position: 'fixed', bottom: 0, width: '100%', padding: '5px 50px' }}>
      NeuVybe LLC © {currentYear}
    </Footer>
  );
};

export default MainFooter;
