import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { TopLevel } from './TopLevel.tsx';
import { AuthProvider } from './hooks/useAuth';
import reportWebVitals from './reportWebVitals';

//INITIALIZE FIREBASE
import { FirebaseService } from './utils/firebase';
new FirebaseService();

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(container);

root.render(
  <StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <TopLevel />
      </BrowserRouter>
    </AuthProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
