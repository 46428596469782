import { store } from './services/ReduxService/store';
import { Provider } from 'react-redux';
import { ActionsContext, boundedActions } from './Actions';
import { App } from './App';

export const TopLevel = () => {
  return (
    <Provider store={store}>
      <ActionsContext.Provider value={{ actions: boundedActions }}>
        <App />
      </ActionsContext.Provider>
    </Provider>
  );
};
