import { useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routeConstants';
import { Layout, Menu, MenuProps, Image } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { FirebaseService } from '../../utils/firebase';
import { useUserCustomClaims } from 'hooks';

const { Header } = Layout;

export const MainHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = FirebaseService.currentUser();

  const userCustomClaims = useUserCustomClaims();

  const NeuVybeLogoBW = useMemo(() => new URL('../../assets/neuvybe_logo_bw.png', import.meta.url), []);

  const [currentMenuRoute, setCurrentMenuRoute] = useState(location.pathname);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    switch (true) {
      case e.key === 'logout': {
        FirebaseService.logout();
        break;
      }
      default: {
        navigate(e.key);
        setCurrentMenuRoute(e.key);
        break;
      }
    }
  };

  const superAdminMenuItems = {
    CHANNELS: {
      label: <span>Channels</span>,
      key: ROUTES.CHANNELS,
    },
    VYBE_COLLECTIONS: {
      label: <span>Vybe Collections</span>,
      key: ROUTES.VYBE_COLLECTIONS,
    },
  };

  const items: MenuProps['items'] = [
    {
      key: ROUTES.HOME,
      icon: <Image style={{ margin: 'auto' }} preview={false} width={50} src={NeuVybeLogoBW.toString()} />,
      style: {
        display: 'flex',
        lineHeight: 0,
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    {
      label: <span>{user?.displayName || user?.email}</span>,
      key: 'userInfo',
      style: { pointerEvents: 'none' },
    },
    {
      label: <span>Tracks</span>,
      key: ROUTES.TRACKS,
    },
    userCustomClaims.isSuperAdmin ? superAdminMenuItems.CHANNELS : null,
    userCustomClaims.isSuperAdmin ? superAdminMenuItems.VYBE_COLLECTIONS : null,
    {
      label: <span>Logout</span>,
      key: 'logout',
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <Header style={{ padding: 0, position: 'fixed', width: '100%', zIndex: 100 }}>
      <Menu
        style={{ lineHeight: '64px' }}
        theme="light"
        onClick={handleMenuClick}
        selectedKeys={[currentMenuRoute]}
        mode="horizontal"
        items={items}
        activeKey={currentMenuRoute}
      />
    </Header>
  );
};

export default MainHeader;
