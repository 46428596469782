//Home for all Redux Observable Epics
import { combineEpics } from 'redux-observable';

// Import all Epics Here
import {
  onFetchTracksEpic,
  onSearchStringChangeEpic,
  onSearchSpotifyTracksEpic,
  onDeleteTracksEpic,
  onDeleteBulkTracksEpic,
  onTrackEditModalCloseEpic,
  onSaveTrackEpic,
  onSaveTrackFromBulkTrackEpic,
  onUpdateTrackEpic,
} from './containers/Tracks/tracksSlice';

import {
  onFetchGoogleChannelsEpic,
  onCreateGoogleChannelsEpic,
  onStartGoogleChannelsEpic,
  onStopGoogleChannelsEpic,
  onDeleteGoogleChannelsEpic,
  onFetchAwsChannelsEpic,
  onCreateAwsChannelsEpic,
  onStartAwsChannelsEpic,
  onStopAwsChannelsEpic,
  onDeleteAwsChannelsEpic,
} from './containers/Channels/channelsSlice';

import {
  onTrackSearchEpic,
  onSaveVybeCollectionEpic,
  onFetchVybeCollectionsEpic,
  onDeleteSelectedVybeCollectionRowsEpic,
  onUpdateVybeCollectionEpic,
  onVybeCollectionEditModalCloseEpic,
} from './containers/VybeCollections/vybeCollectionsSlice';

// Export all Epics Here
export const rootEpic = combineEpics(
  // tracks
  onFetchTracksEpic,
  onSearchStringChangeEpic,
  onSearchSpotifyTracksEpic,
  onDeleteTracksEpic,
  onDeleteBulkTracksEpic,
  onTrackEditModalCloseEpic,
  onSaveTrackEpic,
  onSaveTrackFromBulkTrackEpic,
  onUpdateTrackEpic,

  // channels
  onFetchGoogleChannelsEpic,
  onCreateGoogleChannelsEpic,
  onStartGoogleChannelsEpic,
  onStopGoogleChannelsEpic,
  onDeleteGoogleChannelsEpic,
  onFetchAwsChannelsEpic,
  onCreateAwsChannelsEpic,
  onStartAwsChannelsEpic,
  onStopAwsChannelsEpic,
  onDeleteAwsChannelsEpic,

  // vybeCollections
  onTrackSearchEpic,
  onSaveVybeCollectionEpic,
  onFetchVybeCollectionsEpic,
  onDeleteSelectedVybeCollectionRowsEpic,
  onUpdateVybeCollectionEpic,
  onVybeCollectionEditModalCloseEpic,
);
