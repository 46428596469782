export enum ROUTES {
  HOME = '/',
  LOGIN = 'login',
  CHANNELS = 'channels',
  TRACKS = 'tracks',
  TRACK = 'track',
  VYBE_COLLECTIONS = 'vybe-collections',
  MIXES = 'mixes',
  BULK = 'bulk',
}
