import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Layout } from './containers/Layout';
import { Home } from './containers/Home';
import { Login } from './containers/Login';
import { useAuth } from './hooks/useAuth';

import './App.css';

declare global {
  interface Window {
    audioContext: AudioContext;
    AudioContext: {
      new (): AudioContext;
    };
    webkitAudioContext: AudioContext;
  }
}

export const App = () => {
  const auth = useAuth();

  const UnAuthRoutes = (
    <Routes>
      <Route path="*" element={<Login />} />
    </Routes>
  );

  const AuthRoutes = (
    <div className="App">
      <Routes>
        <Route element={<Layout />}>
          <Route index path="*" element={<Home />} />
        </Route>
      </Routes>
    </div>
  );

  useEffect(() => {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    window.audioContext = new AudioContext();
  }, []);

  const isLoggedIn = !!auth.user;

  if (auth.loading) {
    return (
      <LoadingOutlined
        style={{
          width: '100%',
          position: 'absolute',
          top: '40%',
          fontSize: 56,
          color: '#08c',
        }}
      />
    );
  }

  return isLoggedIn ? AuthRoutes : UnAuthRoutes;
};
