import { FC, useState, useMemo, useContext } from 'react';
import { Modal, Button, Form, Input, Divider, Image, Radio } from 'antd';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { useSelector } from 'react-redux';
import { selectChannelState } from '../channelsSlice';
import { ActionsContext } from '../../../Actions';
import { AwsChannelCreateObject, GoogleChannelCreateObject, Platform } from '../types';

interface ChannelModalProps {
  isModalOpen?: boolean;
  onModalOpen?: () => void;
  onModalClose?: () => void;
}

const initialFormValues: Partial<GoogleChannelCreateObject | AwsChannelCreateObject> = {
  name: '',
  bucketUrl: undefined,
  region: undefined,
};

export const ChannelModal: FC<ChannelModalProps> = (props) => {
  const { actions } = useContext(ActionsContext);
  const { isLoading } = useSelector(selectChannelState);
  const [form] = Form.useForm<GoogleChannelCreateObject | AwsChannelCreateObject>();
  const [platform, setPlatform] = useState<Platform>(Platform.AWS);
  const { isModalOpen = false, onModalOpen, onModalClose } = props;

  const handleModalClose = () => {
    form.resetFields();
    setPlatform(Platform.AWS);
    onModalClose?.();
  };

  const onFinish = async (values: GoogleChannelCreateObject | AwsChannelCreateObject) => {
    switch (true) {
      case platform === Platform.AWS: {
        await actions.channels.createAwsChannel(values as AwsChannelCreateObject);
        break;
      }
      case platform === Platform.GOOGLE: {
        await actions.channels.createGoogleChannel(values as GoogleChannelCreateObject);
        break;
      }
      default: {
        break;
      }
    }
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity<unknown>) => {
    console.error('Validation Failed:', errorInfo.values);
  };

  const NeuVybeLogoTypeBW = useMemo(() => new URL('../../../assets/neuvybe_logotype_bw.png', import.meta.url), []);

  const channelPlatformOptions = [
    { label: Platform.AWS, value: Platform.AWS },
    { label: Platform.GOOGLE, value: Platform.GOOGLE },
  ];

  const isGoolgePlatform = platform === Platform.GOOGLE;

  return (
    <Modal
      closable={!isLoading}
      width="100%"
      open={isModalOpen}
      onOk={onModalOpen}
      onCancel={handleModalClose}
      footer={null}
      style={{
        maxWidth: 'none',
        padding: 0,
        top: 0,
        minHeight: '900px',
        width: '100%',
        margin: 0,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '50px 100px',
        }}
      >
        <Image style={{ pointerEvents: 'none' }} preview={false} width={300} src={NeuVybeLogoTypeBW.toString()} />
        <Divider>Create Channel</Divider>
        <div style={{ marginBottom: '20px' }}>
          <Radio.Group
            options={channelPlatformOptions}
            onChange={(e) => setPlatform(e.target.value)}
            value={platform}
            optionType="button"
          />
          <br />
        </div>
        <Form
          form={form}
          name="basic"
          style={{ maxWidth: 'none', width: '100%' }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={initialFormValues}
        >
          <Form.Item<AwsChannelCreateObject | GoogleChannelCreateObject>
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please enter a name!' }]}
          >
            <Input placeholder="Name" />
          </Form.Item>

          {isGoolgePlatform && (
            <Form.Item<GoogleChannelCreateObject>
              label="Bucket URL"
              name="bucketUrl"
              rules={[{ required: false, message: 'Please enter a bucket url!' }]}
            >
              <Input placeholder="Bucket URL" />
            </Form.Item>
          )}

          {isGoolgePlatform && (
            <Form.Item<GoogleChannelCreateObject>
              label="Region"
              name="region"
              rules={[{ required: false, message: 'Please enter a region!' }]}
            >
              <Input placeholder="Region" />
            </Form.Item>
          )}

          <Form.Item style={{ marginTop: '50px', textAlign: 'center' }}>
            <Button loading={isLoading} type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
