import { User } from 'firebase/auth';
import { useContext, createContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { FirebaseService } from '../utils/firebase';

interface AuthContextType {
  user: User | null | undefined;
  loading: boolean;
  error: Error | undefined;
}

const defaultAuth = {
  user: undefined,
  loading: false,
  error: undefined,
};

const AuthContext = createContext<AuthContextType>(defaultAuth);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = FirebaseService.getAuth();
  const [user, loading, error] = useAuthState(auth);

  const value = { user, loading, error };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
