import { Outlet } from 'react-router-dom';
import MainHeader from '../MainHeader';
import MainFooter from '../MainFooter';

export const Layout = () => {
  return (
    <div style={{ background: '#fff', padding: 0, minHeight: 380 }}>
      <MainHeader />
      <div style={{ paddingTop: 64 }}>
        <Outlet />
      </div>
      <MainFooter />
    </div>
  );
};
