import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Checkbox, Popconfirm, Space, Tooltip } from 'antd';
import type { ColumnGroupType, ColumnType } from 'antd/es/table';
import { actionCreators } from 'Actions';
import { VybeCollection } from '../types';
import dayjs from 'dayjs';

export const getColumns = function (actions: typeof actionCreators) {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Last Updated',
      dataIndex: 'updated_at',
      defaultSortOrder: 'descend',
      sorter: true,
      render: (updated_at: string) => {
        const formattedDate = dayjs(updated_at).format('MM/DD/YYYY');
        const formattedTime = dayjs(updated_at).format('HH:mm:ss');

        return (
          updated_at && (
            <Tooltip placement="top" title={`${formattedDate} ${formattedTime}`}>
              <i>{`${formattedDate} ${formattedTime}`}</i>
            </Tooltip>
          )
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Active',
      dataIndex: 'active',
      sorter: true,
      width: 100,
      align: 'center',
      render: (val: boolean) => <Checkbox checked={val} disabled />,
    },
    {
      title: '# of Tracks',
      dataIndex: 'vybe_collection_tracks',
      key: 'vybe_collection_tracks',
      render: (_, { vybe_collection_tracks }) => {
        return <span>{vybe_collection_tracks?.length}</span>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (vybeCollection: VybeCollection, { id }) => {
        const handleChannelDelete = () => {
          actions.vybeCollections.deleteVybeCollections([id]);
        };

        const editVybeCollection = () => {
          actions.vybeCollections.setCurrentlyEditedVybeCollection(vybeCollection);
          actions.vybeCollections.setVybeCollectionModalVisibilityState(true);
        };

        return (
          <Space size="middle">
            <EditOutlined title="Edit" onClick={editVybeCollection} />
            <Popconfirm
              title="Are you sure?"
              onConfirm={handleChannelDelete}
              placement="right"
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined title="Delete" style={{ cursor: 'pointer' }} />
            </Popconfirm>
          </Space>
        );
      },
    },
  ] as (ColumnGroupType<VybeCollection> | ColumnType<VybeCollection>)[];
};
