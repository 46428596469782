type Destination = {
  Id: string;
  MediaPackageSettings: [];
  Settings: [
    {
      Url: string;
    },
  ];
};

type EgressEndpoints = {
  SourceIp: string;
};

type InputAttachmentAWS = {
  InputAttachmentName: string;
  InputId: string;
  InputSettings: {
    AudioSelectors: [];
    CaptionSelectors: [];
    DeblockFilter: string;
    DenoiseFilter: string;
    FilterStrength: number;
    InputFilter: string;
    Smpte2038DataPreference: string;
    SourceEndBehavior: string;
  };
};

type Maintenance = {
  MaintenanceDay: string;
  MaintenanceStartTime: string;
};

type ChannelState = 'CREATING' | 'CREATE_FAILED' | 'IDLE' | 'STARTING' | 'RUNNING' | 'RECOVERING' | 'STOPPING';

export type AwsChannel = {
  Arn: string;
  ChannelClass: string;
  Destinations: Destination[];
  EgressEndpoints: EgressEndpoints[];
  EncoderSettings: Record<string, unknown>;
  Id: string;
  InputAttachments: InputAttachmentAWS[];
  InputSpecification: Record<string, unknown>;
  Maintenance: Maintenance;
  Name: string;
  RoleArn: string;
  State: ChannelState;
  Tags: Record<string, unknown>;
  inputDetails: {
    AttachedChannels: string[];
    Destinations: {
      Ip: string;
      Port: string;
      Url: string; // use this
    }[];
    Id: string;
    InputClass: string;
    InputDevices: [];
    InputPartnerIds: [];
    InputSourceType: string;
    MediaConnectFlows: [];
    Name: string;
    SecurityGroups: string[];
    Sources: [];
    State: 'ATTACHED';
    Type: 'RTMP_PUSH';
  };
};

type Manifest = {
  muxStreams: string[];
  fileName: string;
  type: string;
  maxSegmentCount: number;
  segmentKeepDuration: Record<string, unknown>;
  useTimecodeAsTimeline: boolean;
};

type InputAttachmentGoogle = {
  key: string;
  input: string;
  automaticFailover: null;
};

type StreamingState =
  | 'STREAMING'
  | 'AWAITING_INPUT'
  | 'STOPPED'
  | 'STARTING'
  | 'STOPPING'
  | 'UPDATING'
  | 'STREAMING_NO_INPUT'
  | 'STREAMING_ERROR'
  | 'STREAMING_STATE_UNSPECIFIED';

export type googleLiveStreamApiChannel = {
  elementaryStreams: Record<string, unknown>[];
  muxStreams: Record<string, unknown>[];
  manifests: Manifest[];
  spriteSheets: [];
  inputAttachments: InputAttachmentGoogle[];
  encryptions: [];
  labels: Record<string, unknown>;
  name: string;
  createTime: Record<string, unknown>;
  updateTime: Record<string, unknown>;
  activeInput: string;
  output: {
    uri: string;
  };
  streamingState: StreamingState;
  streamingError: null;
  logConfig: {
    logSeverity: string;
  };
  timecodeConfig: {
    source: string;
  };
  inputConfig: {
    inputSwitchMode: string;
  };
  inputDetails: {
    name: string;
    type: string;
    uri: string;
    preprocessingConfig: null;
    securityRules: {
      /** SecurityRule ipRanges */
      ipRanges?: string[] | null;
    } | null;
    tier: 'TIER_UNSPECIFIED' | 'SD' | 'HD' | 'UHD';
    inputStreamProperty: {
      lastEstablishTime: {
        seconds: number | Long | string | null;
        nanos: number | Long | string | null;
      };
    } | null;
  };
};

export enum Platform {
  GOOGLE = 'GOOGLE',
  AWS = 'AWS',
}

export type AwsChannelCreateObject = {
  name: string;
};

export type GoogleChannelCreateObject = {
  name: string;
  bucketUrl?: string;
  region?: string;
};
