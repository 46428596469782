import { configureStore, ThunkMiddleware } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { useDispatch } from 'react-redux';
import { createEpicMiddleware } from 'redux-observable';
import { ActionType } from 'typesafe-actions';

import tracksReducer, { trackActions } from '../../containers/Tracks/tracksSlice';
import channelsReducer, { channelActions } from '../../containers/Channels/channelsSlice';
import vybeCollectionsReducer, { vybeCollectionActions } from '../../containers/VybeCollections/vybeCollectionsSlice';
import { rootEpic } from '../../Epics';

// TODO: USE UNION TO COMBINE TYPES
export type RootAction = ActionType<typeof trackActions | typeof channelActions | typeof vybeCollectionActions>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const epicMiddleware = createEpicMiddleware<RootAction, RootAction, any>();

const actionsToExcludeFromLogger: string[] = [];

const logger = createLogger({
  predicate: (getState, action) => !actionsToExcludeFromLogger.includes(action.type),
});

export const store = configureStore({
  reducer: {
    tracks: tracksReducer,
    channels: channelsReducer,
    vybeCollections: vybeCollectionsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([logger as ThunkMiddleware, epicMiddleware]),
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: {},
});

epicMiddleware.run(rootEpic);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
